.commentsWrapPopover {
    padding: 8px 8px 0 8px;
    max-width: 480px;
}

.commentsWrapInline {
    padding: 0 8px 0 8px;
}

.commentContainer {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px;
    margin-bottom: 8px;
    background-color: #fff;
    flex-direction: column;
}

.hidden {
    height: 0;
    width: 0;
    visibility: hidden;
}

.commentUser {
    font-weight: 500;
}

.commentTextReadOnly {
    padding-top: 6px;
}

.commentsCountWrap {
    cursor: pointer;
    padding: 0 8px;
    position: relative;
    display: inline-block;
}

.commentsCountText {
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.commentsCountIcon {
    font-size: 2.2rem !important;
}
