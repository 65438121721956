.event {
    background-color: white;
    margin-bottom: 4px;
    margin-right: 4px;
}

.eventContent {
    padding: 4px 8px;
}

.eventTitle {
    font-weight: bold;
}

.eventLetter {
    background-color: #1c5253;
    color: #fff;
    cursor: default;
    flex: 0 0 24px;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.eventCheckboxWrapper {
    padding: 2px;
    position: relative;
}

.eventCheckboxWrapper:before {
    content: '';
    background-color: #f0f0f0;
    height: 86%;
    left: 0;
    position: absolute;
    top: 7%;
    width: 1px;
}
