.canvas.a4{
    position: relative;
}

.canvas .element{
    position: absolute;
    border-radius: 5px;
    border: 1px solid transparent;
    overflow: hidden;
    cursor: move;
    line-height: normal;
}
.doc-preview .canvas .element {
    cursor: default;
}
.canvas .element.maincontents{
    border-radius: 0;
}

.canvas .element.dragging{
    border: 1px dashed #999;
}

.canvas .element.selected{
    border: 1px solid green;
}

.canvas .element.gridcompanylogo{
    background-image: url('/images/gridcompanylogo-demo.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.canvas .element.gridcompanylogo.logo-61d303ca34bf804ecb898f8e {
    background-image: url('/images/logo-61d303ca34bf804ecb898f8e.svg');
}
.canvas .element.gridcompanylogo.logo-5b5197fb394c3c43ac9d8215 {
    background-image: url('/images/logo-5b5197fb394c3c43ac9d8215.svg');
}
.canvas .element.gridcompanylogo.logo-5e33ee7b6b92105480de385d {
    background-image: url('/images/logo-5e33ee7b6b92105480de385d.svg');
}
.canvas .element.gridcompanylogo.logo-63e9dbfe54894f64dcb4ab69 {
    background-image: url('/images/logo-63e9dbfe54894f64dcb4ab69.svg');
}

.canvas .element h1{
    margin: 0;
}

.canvas .element .summary .label{
    font-weight: 600;
}

.canvas .element .summary .value{
    float: right;
}

.canvas .c5window{
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    position: absolute;
}

.public-DraftStyleDefault-block {
    margin: 0;
    line-height: 1.15;
}