.tribunalTaxationDetailsContent {
    font-size: 16px;
}

.tribunalTaxationDetailsContent .MuiTypography-h6 {
    line-height: 1.3;
}

.MuiDivider-root.divider-2 {
    margin: 0.5rem -1rem;
}
