.diff {
    background-color: #f8de77;
    display: inline-block;
    position: relative;
}
.diff::after {
    content: '';
    background-color: #f8de77;
    position: absolute;
    left: -8px;
    right: -8px;
    bottom: -3px;
    top: -3px;
    margin: auto;

    z-index: 5;
}

.diffText {
    position: relative;
    z-index: 10;
}
