.valueContainer {
    width: 100%;
    cursor: default;
}

.label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
    display: block;
    height: 16px;
    line-height: 15px;
}

.valueWrapper {
    color: rgba(0, 0, 0, 0.87);
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.1875em;
}

.value {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
}
