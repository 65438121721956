.canvas.a4{
    font-family: sans-serif;
    background-color: white;
    overflow: hidden;
    box-shadow: 2px 2px 8px #aaa;
}

.canvas.a4.noborder {
    box-shadow: none;
}

.unassignedelement, .textelement {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    padding: 15px;
    margin: 5px 0;
    background-color: #ececec;
    color: #333333;
    cursor: move;
    user-select: none;
    font-weight: 300;
}

.unassignedelement.added {
    opacity: 0.5;
}

.unassignedelement .main, .textelement .main{
    font-size: 14px;
    font-weight: 500;
    vertical-align: top;
}
.unassignedelement .sub, .textelement .sub{
    font-size: 12px;
}

.textelement .sortorder{
    display: inline-block;
    width: 20px;
    color: #999;
}


.textelement.selected{
    background-color: #dae5ef;
}

.canvas .element.maincontents div{
    background: linear-gradient(#eee, white);
    color: #999;
    border-top: 1px solid #ccc;
    text-align: center;
    padding: 10px;
}


.textsfilter i.fa-times{
    font-size: 18px;
    color: #999;
    transition: color 0.2s;
    cursor: pointer;
}

.textsfilter i.fa-times:hover{
    color: black;
}

