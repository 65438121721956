html,
body {
    height: 100%;
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-family: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #f0f0f0;
}

@media print {
    body {
        background-color: #fff;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}

.appComponent {
    height: 100%;
}

/*
    white outline <Select />
*/

.whiteControl .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.whiteControl .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
}

.whiteControl .MuiSelect-select,
.whiteControl .MuiSelect-icon {
    color: #fff;
}

.whiteControl .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #fff;
}

.MuiInputBase-root .MuiOutlinedInput-inputMarginDense {
    padding-top: 7.5px;
    padding-bottom: 9.5px;
}

/* MAKE INPUTS FLUID */

.MuiFormControl-root,
.MuiInputBase-root {
    width: 100% !important;
}

/*  Set https://github.com/jfcaiceo/react-lightbox-component z-index higher then app-bar and navigation*/

.lightbox-backdrop {
    z-index: 9999 !important;
}

.lightbox-container {
    width: 100%;
}

.lightbox-img-thumbnail--cubit {
    display: inline-block;
    background-size: cover;
    background-position: center;
    position: relative;
}

@media (max-width: 600px) {
    .lightbox-img-thumbnail--cubit {
        width: 100% !important;
    }
    .lightbox-img-thumbnail {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/* utility styles */

.bolder {
    font-weight: 500;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.textRight {
    text-align: right;
}

/* end of utility styles */

.MuiGrid-grid-xs-true {
    min-width: 0; /* fix auto width issues */
}

/*
    global table styles
*/
.tablePadding24 tr > td:first-child,
.tablePadding24 tr > th:first-child {
    padding-left: 24px;
}
.tablePadding24 tr > td:last-child,
.tablePadding24 tr > th:last-child {
    padding-right: 24px;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 0.875rem;
}
#map .ol-zoom {
    top: 108px;
    left: 28px;
}

#map .ol-rotate {
    top: 108px;
    right: 20px;
}
/* Custom scrollbars*/
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: #d8d8d8;
}
::-webkit-scrollbar-thumb {
    background-color: #898989;
    border: 2px solid #d8d8d8;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #575757;
}
/* #region Hack to prevent sticky header jumping when table is first item in expandable panel */
.MuiCollapse-container .tableHeaderRowSticky th {
    top: 0;
}

.MuiCollapse-container.MuiCollapse-entered .tableHeaderRowSticky th {
    top: 64px;
}
/* #endregion */
