:root {
	/*--default-fonts: 'Open Sans', sans-serif;*/
	--default-fonts: sans-serif;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: var(--default-fonts);
}
.letter-container {
	background-color: #fff;
	height: 100%;
}

.letter-wrapper {
	width: 1000px;
	background-color: #fff;
}

.texts h4 {
	margin: 10px 0;
}

.texts {
	padding: 0 71px;
	font-weight: 300;
	background-color: #fff;
}

.letter-container:after {
	content: '';
	z-index: -1;
	position: absolute;
	background-color: #fff;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	min-width: 1000px;
}

.texts .alwaysafter {
	page-break-after: always;
}

.texts .alwaysbefore {
	page-break-before: always;
}

.texts .notinside {
	page-break-inside: avoid;
}

.communication-preview-wrapper .contents {
	padding: 25px;
}

.communication-preview-wrapper .contentheader {
	margin: 20px 0 10px 0;
	font-weight: 600;
}

.communication-preview-wrapper .address {
	margin-left: 10px;
	color: #888;
	font-weight: 300;
}

.communication-preview-wrapper .timestamp {
	float: right;
}

.communication-preview-wrapper .recipientsheader {
	font-size: 12px;
	margin-left: 8px;
	font-weight: 600;
}

.communication-preview-wrapper .recipient {
	background-color: #d7d7d7;
	padding: 3px 8px;
	display: inline-block;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 5px;
}

.communication-preview-wrapper .subject {
	font-weight: 600;
	margin-bottom: 10px;
}

.communication-preview-wrapper.sms .body {
	white-space: pre-wrap;
}

.communication-preview-wrapper .attachment {
	background-color: #efefef;
	padding: 11px 25px;
	margin: 25px;
}

.communication-preview-wrapper .heading {
	margin-top: 20px;
	padding: 0 15px;
	height: 35px;
	line-height: 35px;
	color: white;
	background-color: #4c4c4c;
}

.communication-preview-wrapper .attachment .fa-file-o {
	float: right;
	margin-right: 10px;
	margin-top: 3px;
}

.align-right div {
	text-align: right;
}

.align-center div {
	text-align: center;
}

.align-left div {
	text-align: left;
}
.coverletter .heading {
	padding: 0 15px;
	height: 35px;
	line-height: 35px;
	color: white;
	background-color: #4c4c4c;
	margin-bottom: 30px;
}
 
@page {
	size: A4;
	margin-top: 2cm;
}

@page:first{
	margin-top: 0;
}