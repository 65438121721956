/* FULLCALENDAR OVERRIDES */
.fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
    height: 7em !important;
}

.fc .fc-timegrid-slots tr:first-child .fc-timegrid-slot-label-frame {
    display: none;
}

.fc .fc-timegrid-slots .fc-timegrid-slot-label {
    /* position: relative;*/
    border-top: none;
}

.fc .fc-timegrid-slots .fc-timegrid-slot-label .fc-timegrid-slot-label-frame {
    margin-top: -125%;
    font-size: 15px;
    font-weight: 500;
}

.fc-headerDayName,
.fc-headerDayNumber {
    display: block;
    color: #70757a;
    font-size: 11px;
    font-weight: 500;
}

.fc-headerDayName {
    text-transform: uppercase;
}

.fc-headerDayNumber {
    margin: 0 auto;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: -0.5px;
    text-indent: -0.5px;
    font-weight: 300;
    width: 40px;
    height: 40px;
}

.fc-event .fc-event-main {
    height: 100%;
    width: 100%;
}

.fc-daygrid-day-number {
    margin: 0 auto;
}

.fc-day-today .fc-headerDayNumber,
.fc-day-today .fc-daygrid-day-number {
    background-color: #417505!important;
    border-radius: 50%;
    color: #fff;
}
.fc-day-today .fc-daygrid-day-number {
    width: 24px;
    height: 24px;
    line-height: 16px;
}