.calendarEventInfo {
    background-color: #1c5253;
    height: 100%;
    cursor: pointer;
    border-radius: 3px;
}

.calendarEventInfoFaded {
    opacity: 0.75;
}

.calendarEventInfoTop {
    padding: 4px;
    font-weight: 400;
}

.bolder {
    font-weight: 600;
    white-space: nowrap;
}

.calendarEventInfoBottom {
    border-top: 1px solid rgba(255, 255, 255, 0.35);
    padding: 4px;
    font-weight: 600;
}

.warningIcon {
    color: #ffbb33;
}
